import React, { useMemo, useEffect } from 'react';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFilter } from 'react-icons/fa';
import * as constant from '../../../../../../redux/constants/DashboardConstants';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { useDispatch, useSelector } from 'react-redux';
import { QuickUserToggler } from '../extras/QuiclUserToggler';
import { useSubheader } from '../../_core/MetronicSubheader';
import { setLocalStorage } from '../../../../../../Utils/const';
import DATES_ENABLED_ROUTES from '../../../../../../Utils/date_filter.json';
import FILTER_ENABLED_ROUTES from '../../../../../../Utils/filter.json';
import DatePickerCustom from '../../../../../../Components/Common/DatePicker';
import ToggleButton from './Toggle';
import OrganicButton from './Organic';
import { useToggleShowContext } from "../../../../../../Pages/ShowOrganicContext";

export function Topbar() {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const subheader = useSubheader();
  const { showOrganic, setShowOrganic } = useToggleShowContext();
  const location = useLocation();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        'extras.search.display'
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        'extras.notifications.display'
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        'extras.quick-actions.display'
      ),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        'extras.quick-panel.display'
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        'extras.languages.display'
      ),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle'
      )
    };
  }, [uiService]);

  useEffect(() => {
    if (location.pathname.includes('dashboard')) {
      subheader.setTitle('Dashboard');
    }
    let temp_time = new Date();
    // temp_time.setMinutes(temp_time.getMinutes() + 1);
    temp_time.setHours(temp_time.getHours() + 3);
    setLocalStorage('LOGIN_TIME', new Date());
    setLocalStorage('EXPIRED_TIME', temp_time);
  }, [location.pathname]);

  const UPI_STATUS = useSelector(
    (state) => state?.IdentityScan.BiFilters?.FILTER_LIST?.data?.filter
  );
  return (
    <div className='col-lg-12'>
      <div className='topbar'>
        <div className='col-lg-2'>
          <div className='d-flex justify-content-start flex-start'>
            <h4 className='text-dark font-weight-bold my-7 mr-5'>
              <strong>
                {location.pathname !== '/mtrackit_dashboard'
                  ? subheader.title
                  : ''}
              </strong>
            </h4>
          </div>
        </div>
        <div className='col-lg-3'>
          {showOrganic && (
            <div className='justify-content-end flex-end my-3'>
              {['/lea-dashboard'].includes(location.pathname) ? (
                <OrganicButton />
              ) : null}
            </div>
          )}
        </div>
        <div className='col-lg-3'>
          <div className='justify-content-end flex-end my-3'>
            {UPI_STATUS?.includes('ML_sm_handler') &&
            [
              '/merchant-laundering/dashboard',
              '/merchant-laundering/incidents',
              '/merchant-laundering/report/export',
              '/investment-scams/dashboard',
              '/investment-scams/incidents',
              '/investment-scams/report/export'
            ].includes(location.pathname) ? (
              <ToggleButton />
            ) : null}
          </div>
        </div>

        <div className='col-3'>
          <div className='ml-3 d-flex justify-content-end flex-end my-3 mt-4' style={{width:"61%"}}>
            {Object.keys(DATES_ENABLED_ROUTES).includes(location.pathname) && (
              <DatePickerCustom
                blockFor={DATES_ENABLED_ROUTES[location.pathname].block}
                lockFor={DATES_ENABLED_ROUTES[location.pathname].lock}
              />
            )}
          </div>
        </div>
        <div className='col-lg-1 d-flex justify-content-end align-items-center'>
          <div className='d-flex justify-content-end flex-end my-3'>
            {layoutProps.viewQuickPanelDisplay &&
              (FILTER_ENABLED_ROUTES.includes(location.pathname) ? (
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip id='quick-panel-tooltip'>Filter</Tooltip>}
                >
                  <div
                    className='topbar-item mr-2'
                    data-toggle='tooltip'
                    title='Filter'
                    data-placement='right'
                  >
                    {layoutProps.asideSelfMinimizeToggle && (
                      <div className='btn btn-icon btn-clean btn-lg mr-1'>
                        <button
                          className='brand-toggle btn btn-sm px-0'
                          onClick={() => {
                            dispatch({
                              type: constant.SET_SIDE_DRAWER,
                              setSideDrawer: true
                            });
                          }}
                        >
                          <span className='svg-icon svg-icon-xl svg-icon-primary'>
                            <FaFilter color='#A21094' />
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </OverlayTrigger>
              ) : (
                ''
              ))}
            {layoutProps.viewUserDisplay && <QuickUserToggler />}
          </div>
        </div>
      </div>
    </div>
  );
}
